import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import PageLayout from "../components/new-pagelayout"

const SectorText = ({ pre, sectorName, text }) => {
  return (
    <div class="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
      <div class="max-w-lg">
        <span class="text-xs text-blue-400 font-semibold">{pre}</span>
        <h2 class="mt-8 mb-6 lg:mb-10 lg:pr-8 text-4xl font-semibold">
          {sectorName}
        </h2>
        <p class="text-xl text-gray-500">{text}</p>
      </div>
    </div>
  )
}

const BusinessAreaPage = () => {
  return (
    <PageLayout pageTitle={"Affärsområden"}>
      <div class="container px-4 max-w-3xl mx-auto">
        <section class="py-8">
          <div class="container px-4 mx-auto">
            <h1 class="mb-4 md:mb-8 text-3xl lg:text-4xl font-semibold font-heading">
              Affärsområden
            </h1>
            <div class="flex flex-wrap items-center">
              <div class="inline-block tabort-max-w-xl mb-6 md:mb-0">
                <p class="text-xl text-gray-500">
                  Drone Bones produkter passar för en rad olika kunder, och vi
                  delar upp dem i tre segment.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="relative py-8">
          <div class="relative container px-4 mx-auto">
            <div class="flex flex-wrap items-center -mx-4">
              <SectorText
                pre="Skydd för industrifastigheter"
                sectorName="Industri"
                text={
                  <>
                    Företag har alltid behövt skydda sig från stöld, inbrott och
                    skadegörelse. Vissa industrier är också utsatta för
                    industriellt spionage. Drönartekniken har skapat nya
                    attacker man behöver skydda sig från. Vanligtvis efterfrågas
                    ett komplett system som med flera{" "}
                    <Link to="/produkter#hitta">Drone Bone Hitta</Link> som
                    övervakar en hel inhägnad industrifastighet. Systemet från
                    Drone Bone integreras vanligtvis med ett redan existerande
                    larmsystem.
                  </>
                }
              />
              <div class="relative w-full lg:w-1/2 px-4">
                <StaticImage
                  class="rounded-xl object-cover"
                  alt="Industribyggnad bakom staket och taggtråd"
                  src="../images/industri.jpeg"
                />
              </div>
            </div>
          </div>
        </section>
        <section class="relative py-8">
          <div class="relative container px-4 mx-auto">
            <div class="flex flex-wrap items-center -mx-4">
              <div class="relative w-full lg:w-1/2 px-4">
                <StaticImage
                  class="rounded-xl object-cover"
                  alt="Byggarbetsplats bakom staket och taggtråd"
                  src="../images/bygg.jpeg"
                />
              </div>
              <SectorText
                pre="Portabla skydd för byggarbetsplatser"
                sectorName="Bygg"
                text={
                  <>
                    Varje år stjäls material, verktyg och maskiner till ett
                    uppskattat värde på över 2 miljarder från svenska
                    byggarbetsplatser. Med drönare kan ligor både scouta en
                    plats för att identifiera vad som skall stjälas och även när
                    förberedelserna är klara kontrollera att ingen är på
                    platsen. På byggarbetsplatser görs tillfälliga
                    installationer med portabla enheter, ofta med både{" "}
                    <Link to="/produkter#hitta">Drone Bone Hitta</Link> och{" "}
                    <Link to="/produkter#avvisa">Drone Bone Avvisa</Link> för
                    ett aktivt drönarlarm.
                  </>
                }
              />
            </div>
          </div>
        </section>
        <section class="relative py-8">
          <div class="relative container px-4 mx-auto">
            <div class="flex flex-wrap items-center -mx-4">
              <SectorText
                pre="Skydd för den personliga itegriteten"
                sectorName="Privat"
                text={
                  <>
                    Även för inbrott i privata hem kan drönare användas både vid
                    planering och utförande. I många fall är det stora värdet
                    ändå integritetsskydd när kunder väljer att installera ett
                    drönarlarm runt sin privatbostad.
                  </>
                }
              />
              <div class="relative w-full lg:w-1/2 px-4">
                <StaticImage
                  class="rounded-xl object-cover"
                  alt="Tack på private hus med skog och himmel i bakgrunden"
                  src="../images/privat.jpeg"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </PageLayout>
  )
}

export default BusinessAreaPage
